import "./index.style.css"

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()
  const copyrightName = "EsthétiqueBasilix"
  const copyrightDate = `${currentYear - 1}-${currentYear}`

  return (
    <footer id="footer" className="text-sm bg-white text-neutral-500">
      <div className="footer-container">
        <div className="footer-content flex flex-col items-center gap-y-1 border-t border-neutral-200 py-6 text-sm">
          <div className="content-footer">
            <hr className="h-4 w-[1px] border-l border-neutral-400" />
            <p className="m-0">
              &copy; {copyrightDate} {copyrightName}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export { Footer }
