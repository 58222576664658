import React from "react";
import "./index.style.css";

const Category = () => {
  return (
    <div className="requirements-container">
      <div className="requirements-text">
        <h2>Bienvenue à Esthétique Basilix</h2>
        <p>
          Dans notre centre esthétique, le soin de votre peau et votre bien-être
          sont notre priorité absolue ! Notre équipe d'experts dévoués à la
          qualité est là pour vous offrir une expérience unique et personnalisée
          en utilisant des produits et des techniques de pointe pour garantir
          les meilleurs résultats.
        </p>
        <p>Visitez-nous et découvrez les soins que votre peau mérite !</p>
      </div>
      <div className="requirements-items">
        <a className="requirement-item" href="#face">
          <div className="requirement-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              viewBox="0 0 45.6 50"
              width="45.6"
            >
              <g fill="#af9375">
                <path d="m8.2 16.2c0-8.1 6.6-14.6 14.6-14.6 7.4 0 13.5 5.5 14.5 12.6-2.5 1.9-5.6 3-8.8 3-4.8 0-9.3-2.4-12-6.3l-1.3.9c3 4.4 8 7 13.3 7 3.2 0 6.3-1 9-2.7v.2 14.2h1.5v-14.3c0-8.9-7.3-16.2-16.2-16.2s-16.2 7.3-16.2 16.2v14.2h1.6z"></path>
                <path d="m33.1 39h-.4l-.3.4c-1.9 2.5-5.5 4.1-9.6 4.1-4 0-7.7-1.6-9.6-4.1l-.2-.4h-.4c-.2 0-12.6 1.2-12.6 11h1.6c0-7.6 8.8-9.1 10.7-9.4 2.2 2.7 6.2 4.4 10.6 4.4 4.3 0 8.3-1.7 10.5-4.4 2 .3 10.7 2 10.7 9.4h1.6c-.1-9.8-12.5-11-12.6-11z"></path>
              </g>
            </svg>
          </div>
          <div className="requirement-content">
            <h3>Visage</h3>
            <p>Découvrez le secret d'une peau radieuse et étonnez-vous.</p>
            <a href="#face">
              <span className="arrow">→</span>
            </a>
          </div>
        </a>
        <a className="requirement-item" href="#body-sevice">
          <div className="requirement-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              viewBox="0 0 46.8 50"
              width="46.8"
            >
              <g fill="#af9375">
                <path d="m20.7 6-1.3-.9c-1.7 2.5-4.5 4-7.5 4-3.4 0-6.4-1.9-8-4.7v-.1c-.7-1.3-1.1-2.8-1.1-4.3h-1.6c0 1.8.5 3.5 1.3 5.1 1.8 4.3 4.6 8.5 4.7 8.6 3.5 5.9-.8 11.2-2.2 13-.1.1-.2.3-.3.4-.1.2-.3.3-.4.5-1 1.2-2.2 2.8-3.3 6.7-2 7.2-.4 15.4-.3 15.7l1.6-.3c0-.1-1.6-8.2.3-15 1-3.6 2.1-5 3-6.2.1-.2.3-.4.4-.5s.2-.2.3-.3c4.2-5.3 5-10.3 2.3-14.8 0 0-1.1-1.6-2.3-3.8 1.6 1 3.6 1.6 5.7 1.6 3.4 0 6.7-1.8 8.7-4.7z"></path>
                <path d="m45.8 34.2c-1.1-3.9-2.3-5.5-3.3-6.7-.1-.2-.3-.3-.4-.5-.1-.1-.2-.2-.3-.4-1.4-1.8-5.7-7.1-2.2-12.9.1-.2 3-4.3 4.8-8.7.8-1.5 1.3-3.2 1.3-5h-1.7c0 1.5-.4 3-1.1 4.3v.1c-1.5 2.8-4.5 4.7-8 4.7-3 0-5.8-1.5-7.5-4l-1.4.9c2 2.9 5.3 4.7 8.9 4.7 2.1 0 4-.6 5.7-1.6-1.2 2.2-2.3 3.8-2.4 3.8-2.7 4.5-1.9 9.5 2.3 14.8.1.1.2.2.3.3.1.2.3.3.4.5.9 1.2 2 2.5 3 6.2 1.9 6.9.3 15 .3 15l1.6.3c0-.3 1.7-8.5-.3-15.8z"></path>
                <path d="m29.7 40.1-6.3 4.4-6.3-4.4-.9 1.3 6.4 4.5v3.9h1.6v-3.9l6.4-4.5z"></path>
              </g>
            </svg>
          </div>
          <div className="requirement-content">
            <h3>Corps</h3>
            <p>Nos soins pour une peau plus saine et sans poils.</p>
            <a href="#body-sevice">
              <span className="arrow">→</span>
            </a>
          </div>
        </a>
        <a className="requirement-item" href="#massage">
          <div className="requirement-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="60"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 511.999 511.999"
              xmlSpace="preserve"
            >
              <g>
                <path
                  fill="#af9375"
                  d="M422.4,367.999c-28.237,0-51.2-22.963-51.2-51.2s22.963-51.2,51.2-51.2s51.2,22.963,51.2,51.2   S450.628,367.999,422.4,367.999z"
                />
                <path
                  fill="#af9375"
                  d="M217.6,195.199c-28.228,0-51.2-22.963-51.2-51.2s22.963-51.2,51.2-51.2s51.2,22.963,51.2,51.2   S245.828,195.199,217.6,195.199z"
                />
              </g>
              <g>
                <path
                  fill="#af9375"
                  d="M511.505,390.084c-1.954-6.81-9.071-10.624-15.821-8.798l-83.908,23.979L339.9,357.35   c-2.099-1.399-4.574-2.15-7.1-2.15H38.4c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h290.517l73.574,49.05   c2.125,1.417,4.599,2.15,7.1,2.15c1.178,0,2.364-0.162,3.516-0.486l89.6-25.6C509.508,403.959,513.442,396.876,511.505,390.084z"
                />
                <path
                  fill="#af9375"
                  d="M422.391,380.799c35.294,0,64-28.715,64-64c0-35.294-28.706-64-64-64c-35.285,0-64,28.706-64,64   C358.4,352.085,387.106,380.799,422.391,380.799z M422.391,278.399c21.205,0,38.4,17.195,38.4,38.4c0,21.205-17.195,38.4-38.4,38.4   c-21.205,0-38.4-17.195-38.4-38.4C383.991,295.594,401.186,278.399,422.391,278.399z"
                />
                <path
                  fill="#af9375"
                  d="M217.6,207.999c35.294,0,64-28.715,64-64c0-35.294-28.706-64-64-64c-35.285,0-64,28.706-64,64   S182.306,207.999,217.6,207.999z M217.6,105.599c21.205,0,38.4,17.195,38.4,38.4s-17.195,38.4-38.4,38.4s-38.4-17.195-38.4-38.4   S196.386,105.599,217.6,105.599z"
                />
                <path
                  fill="#af9375"
                  d="M96.674,328.251c1.835,0.913,3.789,1.348,5.709,1.348c4.702,0,9.216-2.586,11.46-7.074   l45.013-90.035l116.147,69.683c6.076,3.635,13.926,1.673,17.562-4.386c3.635-6.059,1.673-13.926-4.386-17.562l-128-76.8   c-3.063-1.826-6.758-2.347-10.163-1.314c-3.422,0.998-6.272,3.379-7.876,6.562l-51.2,102.4   C87.782,317.397,90.351,325.085,96.674,328.251z"
                />
                <path
                  fill="#af9375"
                  d="M332.8,406.399h-320c-7.074,0-12.8,5.726-12.8,12.8s5.726,12.8,12.8,12.8h320   c7.074,0,12.8-5.726,12.8-12.8S339.874,406.399,332.8,406.399z"
                />
              </g>
            </svg>
          </div>
          <div className="requirement-content">
            <h3>Massage</h3>
            <p>Renouvelez votre énergie.</p>
            <a href="#massage">
              <span className="arrow">→</span>
            </a>
          </div>
        </a>
        <a className="requirement-item" href="#products">
          <div className="requirement-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="50"
              fill="#af9375"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 512 512"
            >
              <g>
                <g>
                  <path d="M454.755,184.889H56.714C25.295,184.889,0,210.778,0,242.141v142.241c0,31.364,25.295,56.507,56.714,56.507h398.04    c31.419,0,57.245-25.143,57.245-56.507V242.141C512,210.778,486.174,184.889,454.755,184.889z M493.037,384.382    c0,20.907-17.319,37.544-38.282,37.544H56.714c-20.962,0-37.751-16.637-37.751-37.544V242.141    c0-20.907,16.789-38.289,37.751-38.289h398.04c20.963,0,38.282,17.382,38.282,38.289V384.382z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M436.148,251.259H75.852c-5.236,0-9.482,4.245-9.482,9.481v104.296c0,5.236,4.245,9.481,9.482,9.481h360.296    c5.236,0,9.482-4.245,9.482-9.481V260.741C445.63,255.505,441.384,251.259,436.148,251.259z M426.667,355.556H85.333v-85.333    h341.333V355.556z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M501.97,154.426l-9.759-39.178c-6.168-24.671-30.917-44.137-56.345-44.137H75.603c-25.428,0-50.177,19.514-56.345,44.185    L9.5,154.426c-0.708,2.831-0.072,5.692,1.725,7.993c1.797,2.301,4.553,3.507,7.473,3.507h474.074c2.92,0,5.677-1.157,7.473-3.458    C502.042,160.167,502.678,157.257,501.97,154.426z M30.841,146.963l6.813-27.257c4.016-16.063,21.394-29.632,37.949-29.632    h360.263c16.554,0,33.933,13.569,37.949,29.632l6.814,27.257H30.841z" />
                </g>
              </g>
            </svg>
          </div>
          <div className="requirement-content">
            <h3>Nos produits</h3>
            <p>Voir.</p>
            <a href="#masage">
              <span className="arrow">→</span>
            </a>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Category;
