import axios from "axios"
import React from "react"
import "./inde.style.css"

interface Props {
  title: string
  popup?: boolean
  type?: string
  disableBtn?: boolean
}

const Button: React.FC<Props> = ({ title, popup, type, disableBtn }) => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [textarea, setTextarea] = React.useState("")
  const [error, setError] = React.useState(false)
  const popupRef = React.useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
    return () => {
      document.body.classList.remove("no-scroll")
    }
  }, [open])

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (!email || !textarea) {
      setError(true)
    } else {
      setError(false)
      try {
        await axios.get(
          "https://esthetiquebasilixbackend.onrender.com/sentemailpartener",
          {
            params: {
              email: email,
              textarea: textarea,
            },
          }
        )
        setEmail("")
        setTextarea("")
        alert("Inscription réussie!")
      } catch (error) {
        console.error("Error:", error)
      }
    }
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [open])

  if (popup) {
    return (
      <>
        <p className="custom-btn" onClick={handleClick}>
          {title}
        </p>
        {open && (
          <div className="popup-form-container">
            <div className="popup-form" ref={popupRef}>
              <div className="popup-form-right">
                <button className="close-button" onClick={handleClick}>
                  &times;
                </button>
                <img
                  src="https://dits.md/wp-content/webp-express/webp-images/uploads/artboard-8.png.webp"
                  alt="Nature"
                />
              </div>
              <div className="popup-form-left">
                <h2>
                  Rejoignez notre programme de partenariat et collaborez avec
                  nous.
                </h2>

                <p>
                  Inscrivez-vous pour recevoir des avantages exclusifs, un accès
                  anticipé aux ressources et des opportunités de croissance
                  commune.
                </p>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="email">E-mail adresse</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="E-mail"
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                    className={error ? "input-error" : ""}
                  />
                  <label htmlFor="email">Message</label>
                  <textarea
                    id="email"
                    value={textarea}
                    placeholder="Message"
                    required
                    onChange={e => setTextarea(e.target.value)}
                    className={error ? "input-error" : ""}
                  />
                  {error && (
                    <span className="error-message">
                      This field is required
                    </span>
                  )}
                  <button type="submit">Rejoignez maintenant</button>
                  <p className="note">
                    En vous inscrivant, vous acceptez de recevoir des e-mails
                    contenant nos dernières opportunités de partenariat et mises
                    à jour.
                  </p>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  if (type === "submit") {
    return (
      <button
        className={`custom-btn ${disableBtn ? "custom-disableBtn" : ""}`}
        type="submit"
      >
        {title}
      </button>
    )
  }

  return (
    <a className="custom-btn" href="#form">
      {title}
    </a>
  )
}

export default Button
